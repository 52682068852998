@tailwind base;
@tailwind components;
@tailwind utilities;

// body {
//   @apply bg-black ;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active {
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: #FFF;
//     -webkit-transition: background-color 5000s ease-in-out 0s;
//     box-shadow: inset 0 0 20px 20px transparent;
//     caret-color: #FFF;
// }

.ant-typography {
  font-family: "Inter Tight", sans-serif !important;
}

body, .ant, .ant-tabs {
  font-family: "Inter Tight", sans-serif !important;
}

.ant-typography, .ant-btn, .ant-input {
  font-family: "Inter Tight", sans-serif !important;
}

.unsupported-field{
    display:none !important;
}
.reactflow-link-hide{
    .react-flow__attribution{
        display: none;
    }
}
.inter-tight-100 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  }
  .inter-tight-200 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }
  .inter-tight-300 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  .inter-tight-400 {
    font-family: "Inter Tight", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  .inter-tight-500 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  .inter-tight-600 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }
  .inter-tight-700 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }
  .inter-tight-800 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }
  .inter-tight-900 {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
  }
  .ant-input[disabled] {
    color: black !important;
  }
  .ant-input-affix-wrapper-disabled{
    color: black !important;
  }
  .ant-checkbox-inner{
   border: 2px solid #005b5c;
  }
  .ant-btn-primary{
    box-shadow: 4px 4px 8px 0px #AA81DA !important;
    background: linear-gradient(0deg, #5502B4 0%, #8041C7 100%) !important;
  }
  .ant-tooltip-inner{
    text-align: center !important;
  }

  .autobots-sider-menu .ant-menu-item {
    height: 48px !important;
    padding: 16px !important;
    
  }
  .autobots-sider-menu{
    .ant-menu-title-content{
      color:white
    }
    *{
      color:white
    }
  }
  .list-disc{
    *{
      color:white
    }
  }
  .ant-collapse-header{
    align-items: center !important;
  }

  .ant-btn {
    transition: transform 0.2s ease;
  }
  
  .ant-btn-primary:hover {
    transform: translateY(-2px);
  }
  
  .ant-btn-default:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  }
 
  .ant-modal-close-x {
    color: #C83333; 
  }

  .ant-modal-mask {
    backdrop-filter: blur(50px) !important; 
    background-color: rgba(255, 255, 255, 0.1) !important; 
  }

  .custom-tab {
    .ant-tabs-nav {
      margin: 0 !important;
      font-weight: 400 !important;
    }
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6b22be !important;
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: #7b42bd !important;
  }
  .ant-btn-primary:disabled,.ant-btn-primary.ant-btn-disabled{
    background-color: #70707070 !important; 
    background: #70707070 !important; 
    color: rgba(0, 0, 0, 0.644) !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-btn-default:disabled,.ant-btn-primary.ant-btn-disabled {
    background-color: #70707070 !important; 
    background: #70707070 !important; 
    color: rgba(0, 0, 0, 0.644) !important;
    border-color: #ffffff !important;
    box-shadow: none !important;
  }


  .custom-input-app-link {  
    height: 41px;
    transition: border-color 0.3s;
  }

  
  .custom-input-app-link:hover {
    border-color: #6B22BE !important;
  }
  
   .custom-input-app-link:focus {
     border-color: #6B22BE !important;
     box-shadow: 0 0 0 2px rgba(155, 5, 255, 0.06) !important;
   }

   .ant-card-hoverable:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  }

  .language-plaintext{
    white-space: normal;
  }